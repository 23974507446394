import React, { useEffect, useState } from "react";
import BookingFinance from "components/booking/booking-finance";
import ChargeSection from "components/charge/charge-section";
import CustomerPreviewSection from "components/customer/customer-preview-section";
import ProviderSection from "components/provider/provider-section";
import RefundSection from "components/refund/refund-section";
import ReviewSection from "components/review/review-section";
import ServiceList from "components/service/service-list";
import IntercomBtnWrapper from "components/vendors/intercom-btn-wrapper";
import { BookingDetailCard } from "components/booking/booking-card";
import {
  SectionBlock,
  SectionTitle,
  SectionBody,
} from "components/blocks/section-blocks";
import {
  Row,
  Col,
} from "reactstrap";
import { getTimezoneFromLatLong } from "utils/common/commonFunctions";
import SanitizedHTML from 'react-sanitized-html';

const BookingDetail = (props) => {
  const { booking = {}, isAdmin, loading, financeData } = props;
  const [selectedTimezone, setSelectedTimezone] = useState("");

  useEffect(() => {
    if (booking) {
      getSelectedTimezone();
    }
  }, [booking]);

  const getSelectedTimezone = async () => {
    const timezoneAbbreviation = await getTimezoneFromLatLong(booking?.location?.latitude, booking?.location?.longitude);
    setSelectedTimezone(timezoneAbbreviation);
  }

  // Render
  return (
    <Row>
      <Col md="4">
        <BookingDetailCard booking={booking} loading={loading} selectedTimezone={selectedTimezone} />

        <SectionBlock>
          <SectionTitle title="Finances" />
          <SectionBody>
            <BookingFinance
              booking={booking}
              isAdmin={isAdmin}
              loading={loading}
              financeData={financeData}
            />
          </SectionBody>
        </SectionBlock>
      </Col>

      <Col md="8">
        <SectionBlock>
          <SectionTitle title="Notes" />
          <SectionBody>
            <SanitizedHTML
              html={booking?.displayInstructions}
            />
          </SectionBody>
        </SectionBlock>

        {isAdmin ? (
          <CustomerPreviewSection
            customer={booking.customer}
            loading={loading}
          />
        ) : (
          <ProviderSection
            provider={booking.provider}
            loading={loading}
            className="no-p"
          />
        )}

        <SectionBlock>
          <SectionTitle title="Services" size="lg" />
          <SectionBody className="xs">
            <ServiceList
              type="preview"
              services={booking.services}
              loading={loading}
              isAdmin={true}
            />
          </SectionBody>
        </SectionBlock>

        <ReviewSection
          booking={booking}
          review={booking.review}
          loading={loading}
        />

        <ChargeSection
          booking={booking}
          model={booking.charges}
          loading={loading}
        />

        <RefundSection
          booking={booking}
          model={booking.refunds}
          loading={loading}
        />

        <SectionBlock>
          <SectionBody>
            <h5 className="title">
              Need Help?
              <IntercomBtnWrapper size="xs" color="text-blue" className="ml-2">
                Contact Support
              </IntercomBtnWrapper>
            </h5>
          </SectionBody>
        </SectionBlock>
      </Col>
    </Row>
  );
};

export default BookingDetail;
